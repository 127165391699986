<template>
  <div class="history-page">
    <b-card>
      <b-row class="title history-nav" align-v="baseline">
        <b-col sm="12" md="3" lg="auto" class="mr-auto"><h1>History</h1></b-col>
        <b-col sm="12" md="9" lg="6">
          <div class="row justify-content-around">
            <b-button-group class="scroll">
              <b-button
                variant="primary"
                class="ml-auto d-flex"
                :to="{ name: 'karma-leaderboard' }"
                pill
              >
                <feather-icon icon="ChevronLeftIcon" class="mr-50" />
                <span
                  class="font-weight-bolder d-md-flex align-middle text-uppercase"
                  >Leaderboard</span
                >
              </b-button>
            </b-button-group>
            <v-select
              class="col-sm-7"
              v-model="karma_program_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="karma_program_option"
              :reduce="(val) => val.hashid"
              label="label"
              :clearable="false"
              :searchable="true"
              input-id="karma_program_id"
              name="karma_program_id"
              placeholder="Select Karma Program"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="karmaHistory.length < 1" class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Data Not Available 🕵🏻‍♀️</h2>
        <p class="mb-2">
          Oops! 😖 May data is empty or Try adjusting your filter.
        </p>
        <b-img
          fluid
          :src="require('@/assets/images/pages/Search-rafiki.png')"
          alt="Error page"
          width="500"
        />
      </div>
    </div>
    <b-card v-if="karmaHistory.length > 0">
      <b-row
        class="history-toolbar d-flex align-items-center justify-content-between"
      >
        <b-col
          sm="12"
          md="10"
          v-if="karmaHistory.length > 0"
          class="user-card d-flex align-items-center"
        >
          <div class="user-image mr-1">
            <b-avatar
              style="background-color: transparent"
              title="Leaderboard Employee"
              :src="karmaHistory[0].requested_for.avatar"
              size="4rem"
            />
          </div>
          <div class="user-content align-items-center">
            <h4 class="name font-weight-bolder">
              {{ karmaHistory[0].requested_for.name }}
            </h4>
            <h6 class="destination text-secondary">
              {{ karmaHistory[0].requested_for.designation }}
            </h6>
          </div>
          <div
            class="position d-flex justify-content-between align-items-center"
          >
            <div class="rank d-flex align-items-center">
              <span class="small">Rank: </span>
              <h4 class="fontweight-bolder m-0">{{ myKarmaRank.ranked }}</h4>
            </div>
            <div class="trophy-icon">
              <b-avatar
                style="background-color: transparent"
                :src="require('@/assets/images/illustration/trophy-award.gif')"
                size="5rem"
                class="mb-1"
              />
            </div>
            <div class="points d-flex align-items-center">
              <span class="small">Points: </span>
              <h4 class="fontweight-bolder m-0">
                {{ myKarmaRank.highScore }}
              </h4>
            </div>
          </div>
          <b-pagination
            v-if="!isBusy"
            v-model="currentPage"
            :total-rows="total"
            :per-page="per_page"
            align="center"
            first-number
            last-number
            class="mb-0 w-25 ml-1"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
        <b-col sm="12" md="2" class="toolbar text-right">
          <feather-icon
            icon="ListIcon"
            class="mx-1 text-primary"
            v-b-toggle.listview
            size="30"
          />
          <feather-icon
            icon="GridIcon"
            class="text-primary"
            v-b-toggle.gridview
            size="30"
          />
        </b-col>
      </b-row>
      <b-row class="history list-view">
        <b-collapse
          id="listview"
          visible
          accordion="my-accordion"
          role="tabpanel"
          class="w-100"
        >
          <b-overlay
            :show="isBusy"
            spinner-variant="primary"
            style="min-height: 200px"
          >
            <div
              v-if="karmaHistory.length > 0 && !isBusy"
              class="history-item my-1"
              v-for="(request, index) in karmaHistory"
              v-bind:key="index"
            >
              <div class="history-content">
                <div class="history-header">
                  <div class="event small">
                    Specification
                    <h6 class="d-block font-weight-bolder mt-1">
                      {{ request.karma_catalog.karma_name }}
                    </h6>
                    <p>{{ request.notes }}</p>
                    <a v-if="request.link" target="_blank" :href="request.link"
                      ><feather-icon icon="ChevronRightIcon" /> Reference
                      Link</a
                    >
                    <a
                      v-for="(attachment, index) in request.attachments"
                      :key="index"
                      v-if="attachment.link"
                      target="_blank"
                      :href="attachment.link"
                      ><feather-icon icon="ChevronRightIcon" /> File Link</a
                    >
                  </div>
                  <div class="point small text-right">
                    Points Awarded
                    <h6 class="d-block font-weight-bolder mt-1">
                      {{ request.karma_point }}
                    </h6>
                  </div>
                </div>
                <div class="history-footer">
                  <div class="category sub_category small">
                    Sub Category
                    <h6 class="d-block font-weight-bolder mt-1">
                      {{ request.karma_catalog.sub_karma_category.name }}
                    </h6>
                  </div>
                  <div class="category small">
                    Category
                    <h6 class="d-block font-weight-bolder mt-1">
                      {{ request.karma_catalog.karma_category.name }}
                    </h6>
                  </div>
                  <div class="date small">
                    Date
                    <h6 class="d-block font-weight-bolder mt-1">
                      {{ request.date | formatDate }}
                    </h6>
                  </div>
                </div>
              </div>
              <div class="direction"></div>
            </div>
          </b-overlay>
        </b-collapse>
      </b-row>
      <b-row class="history grid-view">
        <b-collapse
          id="gridview"
          accordion="my-accordion"
          role="tabpanel"
          class="w-100"
        >
          <b-overlay
            :show="isBusy"
            spinner-variant="primary"
            style="height: 100%"
          >
            <div
              v-if="karmaHistory.length > 0"
              class="history-item active"
              v-for="(gridRequest, index) in karmaHistory"
              v-bind:key="index"
            >
              <div class="history-content">
                <div class="history-header d-flex justify-content-between">
                  <div class="event small">
                    Specification
                    <h6 class="font-weight-bolder">
                      {{ gridRequest.karma_catalog.karma_name }}
                    </h6>
                    <h6 class="small mb-0">
                      {{ gridRequest.karma_catalog.sub_karma_category.name }}
                    </h6>
                  </div>
                  <div class="point text-right">
                    Points
                    <span> {{ gridRequest.karma_point }}</span>
                  </div>
                </div>
                <div class="history-footer">
                  <div class="category small">
                    Category
                    <span class="small">{{
                      gridRequest.karma_catalog.karma_category.name
                    }}</span>
                  </div>
                  <div class="date small">
                    Date
                    <span class="small">{{
                      gridRequest.date | formatDate
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="direction"></div>
            </div>
          </b-overlay>
        </b-collapse>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import infiniteScroll from "vue-infinite-scroll";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
  BAvatar,
  BOverlay,
  BAlert,
  BLink,
  BPagination,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import store from "@/store";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import leaderboardStoreModule from "./leaderboardStoreModule";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    BAlert,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    BAvatar,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
    infiniteScroll,
  },
  data() {
    return {
      tempFlag: true,
      lastPage: 10,
      nextPage: 1,
      per_page:9,
      currentPage: 1,
      filterByYear: new Date().getFullYear(),
      userData: this.$cookies.get("userData"),
      isBusy: false,
      redirectEmailNotification: "",
      data_local: {
        title: "",
        get_customfields_data: [],
      },
      searchQuery: "",
      myKarmaRank: [],
      karmaHistory: [],
      sessions: [],
      isBusy: false,
      designationOptions: [],
      karma_program_option: [],
      filterByDesignationId: null,
      karma_program_id: this.$route.params.karma_program_id || null,
    };
  },
  created() {
    this.getKarmaProgramOption();
    this.getHistory();
    this.getMyKarmaRank();
  },
  mounted() {},
  watch: {
    searchQuery(val) {
      if (val.length > 3 || val.length == 0) {
        this.getHistory();
        this.getMyKarmaRank();
      }
    },
    currentPage(val) {
      this.getHistory();
    },
    filterByYear() {
      this.getHistory();
      this.getMyKarmaRank();
    },
    karma_program_id() {
      this.getHistory();
      this.getMyKarmaRank();
    },
  },
  methods: {
    loadMore: function () {
      if (
        (this.nextPage === 1 && this.tempFlag === true) ||
        (this.nextPage > 1 && this.tempFlag === false)
      ) {
        this.tempFlag = false;
        if (this.currentPage < this.lastPage) {
          this.isBusy = true;

          let params = {};
          params.page = this.nextPage;
          params.q = this.searchQuery;
          params.year = this.filterByYear;
          params.type = this.filterByType;
          params.filterByDesignationId = this.filterByDesignationId;

          this.$http
            .post("/karma-history", params)
            .then((res) => {
              this.karmaHistory = this.karmaHistory.concat(res.data.data);
              this.nextPage += 1;
              this.lastPage = res.data.meta.last_page;
              this.per_page = res.data.meta.per_page;
              this.currentPage = res.data.meta.current_page;
              this.isBusy = false;
            })
            .catch((error) => {
              console.log(error);
              this.isBusy = false;
            });
        }
      }
    },
    changeSession(year) {
      console.log(year);
      this.filterByYear = year;
    },
    getHistory() {
      console.log("getHistory");
      const self = this;
      if (!self.karma_program_id) {
        return true;
      }
      let data = {};
      data.url = "karma-history";
      data.params = {
        q: self.searchQuery,
        page: self.currentPage,
        status: "approved",
        requested_for: this.$route.params.id,
        karma_program_id: self.karma_program_id,
      };
      self.isBusy = true;
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.karmaHistory = res.data.data;
          self.karma_program_option = res.data.karma_program_option;
          if (self.karma_program_option.length > 0 && !self.karma_program_id) {
            self.karma_program_id = self.karma_program_option[0].hashid;
          }
          self.nextPage = res.data.meta.current_page + 1;
          self.lastPage = res.data.meta.last_page;
          self.currentPage = res.data.meta.current_page;
          self.total = res.data.meta.total;
          self.sessions = res.data.sessions;
          self.per_page = res.data.meta.per_page;
          if (res.data.data.length > 0) {
            self.userData = res.data.data[0];
          }
          self.isBusy = false;
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Something went wrong",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Something went wrong`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          self.isBusy = false;
        });
    },
    getMyKarmaRank() {
      const self = this;
      if (!self.karma_program_id) {
        return true;
      }
      let data = {};
      data.url = "get-user-karma-rank/";
      data.params = {
        status: "approved",
        user_hashid: this.$route.params.id,
        karma_program_id: self.karma_program_id,
      };
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.myKarmaRank = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Something went wrong",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Something went wrong`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          this.isBusy = false;
        });
    },
    getKarmaProgramOption() {
      const self = this;
      let data = {};
      data.url = "list/karmaProgram";
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.karma_program_option = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Something went wrong",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Something went wrong`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          this.isBusy = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";

.filepond--credits {
  display: none;
}
</style>

<style lang="scss" scoped>
.assign-interview-btn {
  position: absolute;
  right: 180px;
  bottom: 60px;
  border: solid 1px;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
